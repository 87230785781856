import ProjectService from '@/services/API/project.service';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import StarRating from '@/components/StarRating/StarRating.vue';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import socketClient from '../../../services/SOCKET';
import ProcessingConst from '@/constants/ProcessingConst';
import emitSoundMixin from '@/mixin/emitSound';
import DecisionService from '@/services/API/decision.service';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	components: {
		BottomButton,
		StarRating,
	},
	mixins: [emitSoundMixin],

	data() {
		return {
			middleContent: ' 様 ／ ',
			rating: 0,
			totalZoomMettingTime: '1 時間 09 分',
			showButton: false,
			NFT_DESCRIPTION: ProcessingConst.NFT_DESCRIPTION,
			onlydecision: false,
		};
	},

	computed: {
		// Get data from store
		...mapState([
			'projectId',
			'token',
			'userId',
			'projectInfo',
			'creatorInfo',
			'clientInfo',
			'managementMasterInfo',
			'appLogo',
			'appLogoPlain',
		]),

		isProjectFinished() {
			return this.projectInfo.id && this.projectInfo['creator_finished'] === 1;
		},

		showContent() {
			return !!this.projectInfo.id;
		},
	},
	watch: {
		projectInfo: {
			handler(newProjectInfo) {
				if (newProjectInfo?.id) {
					if (newProjectInfo['creator_rating'] > 0)
						this.rating = newProjectInfo['creator_rating'];

					if (newProjectInfo['status'] !== ProcessingConst.FREEZE_STATUS) {
						this.showButton = true;
					}

					let hour =
						newProjectInfo['zoom_session_duration'] < 60
							? 0
							: Math.floor(newProjectInfo['zoom_session_duration'] / 60);
					let minute = Math.round(newProjectInfo['zoom_session_duration']) % 60;
					this.totalZoomMettingTime = hour + ' 時間 ' + minute + ' 分';
				}
			},
			immediate: true,
		},
	},
	methods: {
		/* eslint-disable no-mixed-spaces-and-tabs */
		async onClick() {
			this.$store.commit('setIsAppProcessing', true);
			this.emitMixinStartingSound();
			let response = await ProjectService.get(this.projectId);
			if (!response || response.status !== 200) {
				throw 'Get Project failed';
			}
			if (response.data['only_decision'] === 1) {
				const startStatus = {
					only_decision: 0,
					creator_start: 1,
					project_start_date: new Date().toDateString(),
					status: 0,
				};
				await this._updateProject(this.projectId, startStatus);
				this.$emit('on-send-start-info', true);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.creatorInfo.id,
						event_name: 'update_project_status',
					},
					room: 'user' + this.creatorInfo.id,
				});
			} else {
				const startStatus = {
					creator_start: 1,
					project_start_date: new Date().toDateString(),
				};
				await this._updateProject(this.projectId, startStatus);
				this.$emit('on-send-start-info', true);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.creatorInfo.id,
						event_name: 'update_project_status',
					},
					room: 'user' + this.creatorInfo.id,
				});
			}
			// this.sendNotification();

			await this.direct();

			this.$store.commit('setIsAppProcessing', false);
		},
		async onClickDecision() {
			this.$store.commit('setIsAppProcessing', true);
			this.emitMixinStartingSound();
			let response = await DecisionService.get(this.projectId);
			if (!response || response.status !== 200) {
				throw 'Get Decision failed';
			}
			if (response.data['project_id'] !== this.projectId) {
				await this._createNewDecision();
			}
			const startStatus = {
				only_decision: 1,
				creator_start: 1,
				status: 2,
				project_start_date: new Date().toDateString(),
			};
			await this._updateProject(this.projectId, startStatus);
			this.$emit('on-send-start-info', true);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.creatorInfo.id,
					event_name: 'update_project_status',
				},
				room: 'user' + this.creatorInfo.id,
			});
			// this.sendNotification();

			this.$router.push('decision');

			this.$store.commit('setIsAppProcessing', false);
		},
		async _createNewDecision() {
			let decisionData = {
				project_id: this.projectId,
				image_list: [],
				update_user: this.userId,
			};

			let response = await DecisionService.post({
				doc_content: decisionData,
			});

			if (!response || response.status !== 200) {
				throw 'Create Decision failed';
			}

			console.log('%c Create Decision successfully', 'color: green');
		},
		async direct() {
			const endPoint = await this.getCreatorUrlEndPoint(this.projectInfo);
			this.$router.push(endPoint);
		},

		// sendNotification() {
		// 	let emailContentHeader =
		// 		this.clientInfo['client_name'] + '様' + '/' + this.projectInfo['project_name'];
		// 	let emailContentFooter = 'スタートしました。';
		// 	let emailContent = `${emailContentHeader}\n${emailContentFooter}`;
		// 	moment.locale('ja');
		// 	let mailContents = {
		// 		destinations: [
		// 			//replace with your email for testing
		// 			this.creatorInfo['email_address'],
		// 		],
		// 		sender: this.managementMasterInfo['sender_email_address'],
		// 		subject:
		// 			'【COMODE】' +
		// 			'[' +
		// 			this.clientInfo['client_name'] +
		// 			'/' +
		// 			this.projectInfo['project_name'] +
		// 			']' +
		// 			' ' +
		// 			this.projectInfo['project_name'] +
		// 			'スタート',
		// 		template: 'processmail_creator',
		// 		template_params: {
		// 			projectName: this.projectInfo['project_name'],
		// 			projectId: this.projectId,
		// 			screenName: 'schedule',
		// 			clientName: this.clientInfo['client_name'],
		// 			clientId: this.clientInfo['id'],
		// 			sendMailDate: moment().format('LLL'),
		// 			emailContent: emailContent,
		// 			appLogo: this.appLogo,
		// 			appLogoFooter: this.appLogoPlain,
		// 			processingScreenUrl: `${this.projectInfo['process_creator_url']}schedule`,
		// 			clientRegisterParams:
		// 				`register_name=${this.clientInfo['client_name']}&` +
		// 				`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
		// 				`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
		// 		},
		// 	};
		// 	let notifyContent =
		// 		this.projectInfo['notify_method'] == 1
		// 			? toLineMessageTemplate({
		// 					title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
		// 					message: `${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}\nスタートしました。`,
		// 					currentUrl: `${this.projectInfo['process_creator_url']}schedule`,
		// 			  })
		// 			: mailContents;
		// 	sendNotify(notifyContent, this.projectInfo['notify_method'], [
		// 		this.creatorInfo['id'],
		// 	]);
		// },

		onOpenChatRoomClick() {
			this.$emit('on-open-chat-room');
		},

		onOpenNftUpload() {
			this.$router.push('nft');
		},

		/**
		 * Update NEXT processing screen status
		 * @param {Integer} projectId
		 * @param {Object} content
		 */
		async _updateProject(projectId, content) {
			let response = await ProjectService.update(projectId, content);
			if (response.status !== 200) {
				throw 'Update project failed';
			}
			console.log('Update project successfully');
		},

		async onRating(rating) {
			this.$store.commit('setIsAppProcessing', true);

			await this._updateProject(this.projectId, { creator_rating: rating });

			let projectInfo = { ...this.projectInfo, creator_rating: rating };
			this.$store.commit('setProjectInfo', projectInfo);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Get creator urlEndPoint base on project status
		 * @param {object} project Project Object
		 * @returns urlEndPoint
		 */
		getCreatorUrlEndPoint(project) {
			return ProcessingConst.STATUS_LIST[project.status].value;
		},
	},
};
